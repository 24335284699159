<template>
  <div class="w-80">
    <!--begin::Content header-->
    <div
      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    >
      <span class="font-weight-bold font-size-3 text-dark-60">
        Déjà un compte ?
      </span>
      <router-link
        class="font-weight-bold font-size-3 ml-2"
        :to="{ name: 'login' }"
      >
        Connexion
      </router-link>
    </div>
    <!--end::Content header-->

    <!--begin::Signup-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">Inscription</h3>
        <p class="text-muted font-weight-semi-bold">
          Veuillez saisir vos informations
        </p>
      </div>

      <!-- Registration -->
      <form-wizard
        :title="'Je m\'aqcuitte de mon paiement'"
        :subtitle="''"
        shape="tab"
        :nextButtonText="'Suivant'"
        :finishButtonText="'Procéder'"
        :backButtonText="'Précédent'"
        @on-complete="handleEndOfForm"
        color="#006c68"
      >
        <tab-content
          title="Informations personnelles"
          :before-change="validatePersonalInformations"
        >
          <div class="container">
            <div class="row">
              <div class="col-12 mt-4">
                <h3 class="text-center section-title">
                  Afin de terminer votre inscription,<br />
                  vous devez vous acquitter du paiement<br />
                  de la somme de 5.000FCFA soit 10€ ou 10$<br />
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="form-group name">
                  <label for="donor_name">Nom *</label>
                  <input
                    class="form-control"
                    id="donor_name"
                    type="text"
                    v-model="donor.name"
                    :placeholder="namePlaceholder"
                  />
                </div>
              </div>
              <div class="col-md-8 col-sm-12">
                <div class="form-group forename">
                  <label for="donor_name">Prénoms *</label>
                  <input
                    class="form-control"
                    id="donor_forname"
                    type="text"
                    v-model="donor.forName"
                    :placeholder="fornamePlaceholder"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group email col-7">
                <label for="donor_email">Email *</label>
                <input
                  class="form-control"
                  id="donor_email"
                  type="text"
                  v-model="donor.email"
                  :placeholder="emailPlaceholder"
                />
              </div>

              <div class="form-group phone col-5">
                <label for="donor_phone"
                  >Téléphone (précédé de l'indicatif) ex: 2250102030405 *</label
                >
                <input
                  class="form-control"
                  id="donor_phone"
                  type="text"
                  v-model="donor.phone"
                  :placeholder="phonePlaceholder"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group email col-6">
                <label for="password">Mot de passe *</label>
                <input
                  class="form-control"
                  id="password"
                  type="password"
                  v-model="donor.password"
                  :placeholder="pwdPlaceholder"
                />
              </div>

              <div class="form-group phone col-6">
                <label for="password_confirm"
                  >Confirmation du mot de passe *</label
                >
                <input
                  class="form-control"
                  id="password_confirm"
                  type="password"
                  v-model="donor.passwordConfirm"
                  :placeholder="pwdPlaceholder"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group address col-12">
                <label for="donor_email">Adresse 1 *</label>
                <input
                  class="form-control"
                  id="donor_address1"
                  type="text"
                  v-model="donor.addressLine1"
                  :placeholder="addressPlaceholder"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group address col-12">
                <label for="donor_email">Adresse 2</label>
                <input
                  class="form-control"
                  id="donor_address2"
                  type="text"
                  v-model="donor.addressLine2"
                  :placeholder="addressPlaceholder"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group country col-md-2 col-sm-4">
                <label for="donor_email">Pays *</label><br />
                <vue-country-code
                  @onSelect="onSelect"
                  :preferredCountries="['ci', 'fr', 'us']"
                >
                </vue-country-code>
              </div>

              <div class="form-group city col-md-10 col-sm-8">
                <label for="donor_email">Ville *</label>
                <input
                  class="form-control"
                  id="donor_city"
                  type="text"
                  v-model="donor.city"
                  :placeholder="cityPlaceholder"
                />
              </div>
            </div>
            <div class="row"></div>
            <div class="row"></div>
          </div>
        </tab-content>
        <tab-content title="Récapitulatif">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h3>Vous êtes sur le point d'effectuer votre paiement</h3>
                <p>
                  Ci-dessous le récapitulatif. Lorsque vous serez prêt, cliquez
                  sur le bouton "Procéder"
                </p>
              </div>
              <div class="recap col-12 p-4 my-3">
                <div class="row p-2">
                  <div class="col-5 field-value">
                    {{ selectedOffering }}
                  </div>
                </div>

                <div class="row p-2">
                  <div class="col-5 field-naming">
                    Montant à payer
                  </div>

                  <div class="col-5 field-value">
                    {{ amount }}
                    <span v-if="currency !== 'XOF'"
                      >{{ currency }} soit {{ xofAmount }} FCFA
                    </span>
                    <span v-else>FCFA</span>
                  </div>
                </div>
              </div>
              <div class="payment-type-selection">
                <div>
                  <label for="payment-type"
                    >Veuillez sélectionner un moyen de paiement</label
                  >
                </div>
                <div class="form-group">
                  <select
                    id="payment-type"
                    v-model="paymentType"
                    class="form-select form-control"
                    @change="setMobileMoneyPhone"
                  >
                    <option></option>
                    <option value="visa_mastercard"
                      >Carte Bancaire (Visa, MasterCard)</option
                    >
                    <option value="mobile_money">Mobile Money</option>
                  </select>
                </div>
              </div>
              <form
                v-if="paymentType === 'visa_mastercard'"
                method="post"
                :action="formAction"
                name="offeringFinalForm"
              >
                <input
                  type="hidden"
                  name="profile_id"
                  :value="this.config.profileId"
                />
                <input
                  type="hidden"
                  name="access_key"
                  :value="this.config.accessKey"
                />
                <input
                  type="hidden"
                  name="transaction_uuid"
                  :value="this.transactionId"
                />
                <input
                  type="hidden"
                  name="signed_field_names"
                  value="access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,bill_address1,override_custom_cancel_page,override_custom_receipt_page"
                />
                <input
                  type="hidden"
                  name="unsigned_field_names"
                  value="signature,bill_to_forename,bill_to_surname,bill_to_email,bill_to_address_line1,bill_to_address_line2,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,item_0_sku"
                />
                <input
                  type="hidden"
                  name="signed_date_time"
                  :value="signedDateTime"
                />
                <input type="hidden" name="locale" value="fr-fr" />
                <input type="hidden" name="transaction_type" value="sale" />
                <input
                  type="hidden"
                  name="reference_number"
                  :value="this.referenceNumber"
                />
                <input type="hidden" name="amount" :value="xofAmount" />
                <input
                  type="hidden"
                  name="bill_address1"
                  :value="donor.addressLine1"
                />
                <input type="hidden" name="bill_city" :value="donor.city" />
                <input
                  type="hidden"
                  name="customer_email"
                  :value="donor.email"
                />
                <input
                  type="hidden"
                  name="customer_lastname"
                  :value="donor.forName"
                />
                <input type="hidden" name="currency" value="XOF" />
                <input type="hidden" name="signature" :value="signature" />
                <input type="hidden" name="item_0_sku" :value="item_0_sku" />
                <input
                  type="hidden"
                  name="bill_to_forename"
                  :value="donor.name"
                /><br />
                <input
                  type="hidden"
                  name="bill_to_surname"
                  :value="donor.forName"
                /><br />
                <input
                  type="hidden"
                  name="bill_to_email"
                  :value="donor.email"
                /><br />
                <input
                  type="hidden"
                  name="bill_to_address_line1"
                  :value="donor.addressLine1"
                /><br />
                <input
                  type="hidden"
                  name="bill_to_address_line2"
                  :value="donor.addressLine2"
                /><br />
                <input
                  type="hidden"
                  name="bill_to_address_city"
                  :value="donor.city"
                /><br />
                <input
                  type="hidden"
                  name="bill_to_address_state"
                  :value="donor.city"
                /><br />
                <input
                  type="hidden"
                  name="bill_to_address_country"
                  :value="donor.country"
                /><br />
                <input
                  type="hidden"
                  name="override_custom_cancel_page"
                  :value="this.cancel"
                /><br />
                <input
                  type="hidden"
                  name="override_custom_receipt_page"
                  :value="this.receipt"
                /><br />
                <input
                  type="hidden"
                  name="bill_to_address_postal_code"
                  :value="225"
                /><br />
              </form>

              <form
                v-else-if="paymentType === 'mobile_money'"
                method="post"
                :action="formAction"
                name="csuccothMobileMoneyForm"
              >
                <h2>
                  Vous êtes sur le point d'effectuer un paiement par Mobile
                  Money
                </h2>
                <div class="form-group">
                  <label for="number_for_debit">Huméro à débiter</label>
                  <input
                    id="number_for_debit"
                    class="form-control"
                    type="text"
                    name="number_for_debit"
                    v-model="this.donor.phone"
                  />
                </div>
              </form>
            </div>
          </div>
        </tab-content>
      </form-wizard>
      <!-- Registration -->
    </div>
    <!--end::Signup-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
.w-80 {
  width: 80%;
}
</style>

<script>
import { mapState } from "vuex";
import { REGISTER } from "@/core/services/store/auth.module";
import { LOGOUT } from "@/core/services/store/auth.module";

// Form wizard
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import { validationMixin } from "vuelidate";
import { email, required, minLength } from "vuelidate/lib/validators";

import VueCountryCode from "vue-country-code";

import API from "../../../core/services/api";

import dateFormat from "dateformat";

import Swal from "sweetalert2";

// CinetPay
// import { CinetPay } from "./cinetpay";

export default {
  mixins: [validationMixin],
  components: {
    FormWizard,
    TabContent,
    VueCountryCode
  },
  name: "register",
  data() {
    return {
      // Remove this dummy login info
      createdUserId: null,
      form: {
        email: "",
        password: ""
      },
      numberForDebit: "",
      paymentType: "",
      offeringType: [],
      selectedOffering: "",
      currency: "",
      amount: "5000.00",
      xofAmount: "5000.00",
      amountPlaceholder: "",
      transactionId: "",
      config: {
        // profileId: "94618BA5-94C9-4644-90C0-D71D952CB1E4",
        profileId: "7A0007BD-054F-4B7D-BD6A-E0E425103156",
        // accessKey: "d7dc33ea141630ef91b0737771e79730"
        accessKey: "08663e6b21853cd9956944ed0bda97bf"
      },
      signedDateTime: "",
      signParams: "",
      formAction: "https://secureacceptance.cybersource.com/pay",
      signature: "",
      referenceNumber: "",
      donor: {
        name: "",
        forName: "",
        email: "",
        city: "",
        country: "",
        addressLine1: "",
        addressLine2: "",
        passwordConfirm: "",
        phone: ""
      },
      namePlaceholder: "Votre nom",
      fornamePlaceholder: "Votre prénom",
      phonePlaceholder: "2250000000000",
      emailPlaceholder: "Votre email",
      addressPlaceholder: "Votre adresse",
      cityPlaceholder: "Votre ville de résidence",
      pwdPlaceholder: "•••••••••••••••••",
      item_0_sku: "Inscription CSuccoth",
      receipt: "https://csuccoth-backend.vasesdhonneur.org/response-manager",
      cancel: "https://csuccoth-backend.vasesdhonneur.org/response-manager"
    };
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  mounted() {
    let cinetPayScript = document.createElement("script");
    cinetPayScript.setAttribute(
      "src",
      "https://cdn.cinetpay.com/seamless/main.js"
    );
    document.head.appendChild(cinetPayScript);
  },
  methods: {
    setMobileMoneyPhone: function() {
      if (typeof this.donor.phone !== "undefined")
        this.numberForDebit = this.donor.phone;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    handleEndOfForm: async function() {
      // event.preventDefault();
      this.disabled = true;
      let btn = document.getElementsByClassName("wizard-btn").item(1);
      btn.setAttribute("disabled", "");
      btn.classList.add("spinner", "spinner-light", "spinner-right");
      setTimeout(() => {
        btn.removeAttribute("disabled");

        btn.classList.remove("spinner", "spinner-light", "spinner-right");
      }, 5000);

      // prevent default
      btn.addEventListener("click", function(event) {
        event.preventDefault();
      });
      // end prevent;
      const vm = this;
      await API.verifyAuth(this.donor.email).then(async function(Response) {
        if (Response.status === 200 && Response.data.codeStatus === 800) {
          Swal.fire({
            title: "",
            text: `Vous avez déjà un compte. Vous pourrez procéder au paiement`,
            icon: "warning",
            confirmButtonClass: "btn btn-secondary"
          });

          // creating payment
          await API.createPayment({
            amount: parseFloat(vm.amount),
            createdAt: vm.signedDateTime,
            status: "pending",
            associatedUser: `api/users/${Response.data.data.id}`,
            transactionId: vm.transactionId
          })
            .then(function(Response) {
              if (Response.status === 201) {
                Swal.fire({
                  title: "",
                  text: `Félicitation votre compte a été créé avec succès. Veuillez vous connecter`,
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary"
                });
                // redirect
                // window.location.href = "/login";
                // eslint-disable-next-line no-empty
              } else {
                Swal.fire({
                  title: "",
                  text: `Une erreur est survenue lors de la tentative de création du compte. Veuillez réessayer`,
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary"
                });
              }
            })
            .catch(function(error) {
              Swal.fire({
                title: "",
                text: `${error}`,
                icon: "error",
                confirmButtonClass: "btn btn-secondary"
              });
              window.location.href = "/login";
            });

          await API.signData({
            dataToSign: {
              profile_id: vm.config.profileId,
              access_key: vm.config.accessKey,
              transaction_uuid: vm.transactionId,
              signed_field_names:
                "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,bill_address1,override_custom_cancel_page,override_custom_receipt_page",
              unsigned_field_names:
                "signature,bill_to_forename,bill_to_surname,bill_to_email,bill_to_address_line1,bill_to_address_line2,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,item_0_sku",
              bill_address1: vm.donor.addressLine1,
              bill_city: vm.donor.city,
              bill_country: vm.donor.country,
              customer_email: vm.donor.email,
              customer_lastname: vm.donor.forName,
              signed_date_time: vm.signedDateTime,
              locale: "fr-fr",
              transaction_type: "sale",
              reference_number: vm.referenceNumber,
              amount: vm.xofAmount,
              currency: "XOF",
              item_0_sku: "",
              override_custom_receipt_page: vm.receipt,
              override_custom_cancel_page: vm.cancel
            }
          }).then(function(Response) {
            vm.signature = Response.data.signedData;
          });

          await window.setTimeout(document.offeringFinalForm.submit(), 6500);

          return Response.data.id;
        }
      });

      await API.addUser({
        name: this.donor.name,
        firstName: this.donor.forName,
        email: this.donor.email,
        country: this.donor.country,
        city: this.donor.city,
        password: this.donor.password,
        username: this.donor.email,
        status: false
      })
        .then(async function(Response) {
          if (Response.status === 201) {
            vm.createdUserId = Response.data.id;
            // send Sms
            await API.sendSms({
              recipient: vm.donor.phone,
              sender_id: "CSuccoth",
              type: "plain",
              message: `Félicitations, votre compte a été créé avec succès. \n Nom d'utilisateur: ${vm.donor.email}\nMot de passe: ${vm.donor.password}`
            });
            await API.createPayment({
              amount: parseFloat(vm.amount),
              createdAt: vm.signedDateTime,
              status: "pending",
              associatedUser: `api/users/${Response.data.id}`,
              transactionId: vm.transactionId
            })
              .then(function(Response) {
                if (Response.status === 201) {
                  Swal.fire({
                    title: "",
                    text: `Félicitation votre compte a été créé avec succès. Veuillez vous connecter`,
                    icon: "success",
                    confirmButtonClass: "btn btn-secondary"
                  });
                  // redirect
                  // window.location.href = "/login";
                  // eslint-disable-next-line no-empty
                } else {
                  Swal.fire({
                    title: "",
                    text: `Une erreur est survenue lors de la tentative de création du compte. Veuillez réessayer`,
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary"
                  });
                }
              })
              .catch(function(error) {
                Swal.fire({
                  title: "",
                  text: `${error}`,
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary"
                });
                window.location.href = "/login";
              });
            return Response.data.id;
          } else {
            await Swal.fire({
              title: "",
              text: `${Response.data["hydra:description"]}`,
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            });
            window.location.href = "/login";
          }
        })
        .catch(function() {
          Swal.fire({
            title: "",
            text: `${Response.data["hydra:description"]}`,
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
          window.location.href = "/login";
        });

      if (this.paymentType === "visa_mastercard") {
        await API.signData({
          dataToSign: {
            profile_id: this.config.profileId,
            access_key: this.config.accessKey,
            transaction_uuid: this.transactionId,
            signed_field_names:
              "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,bill_address1,override_custom_cancel_page,override_custom_receipt_page",
            unsigned_field_names:
              "signature,bill_to_forename,bill_to_surname,bill_to_email,bill_to_address_line1,bill_to_address_line2,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,item_0_sku",
            bill_address1: this.donor.addressLine1,
            bill_city: this.donor.city,
            bill_country: this.donor.country,
            customer_email: this.donor.email,
            customer_lastname: this.donor.forName,
            signed_date_time: this.signedDateTime,
            locale: "fr-fr",
            transaction_type: "sale",
            reference_number: this.referenceNumber,
            amount: this.xofAmount,
            currency: "XOF",
            item_0_sku: "",
            override_custom_receipt_page: this.receipt,
            override_custom_cancel_page: this.cancel
          }
        }).then(function(Response) {
          vm.signature = Response.data.signedData;
        });

        window.setTimeout(document.offeringFinalForm.submit(), 6500);
      } else {
        /* eslint-disable */
	console.log("CInetpay");
        CinetPay.setConfig({
          apikey: "6440994495c066a6b5c3118.48292011", //   YOUR APIKEY
          site_id: "500940", //YOUR_SITE_ID
          notify_url: "http://localhost:8000/response-manager",
          mode: "PRODUCTION"
        });
        await CinetPay.getCheckout({
          transaction_id: Math.floor(Math.random() * 100000000).toString(), // YOUR TRANSACTION ID
          amount: 5000,
          currency: "XOF",
          channels: "ALL",
          description: "Paiement C'Succoth",
          //Fournir ces variables pour le paiements par carte bancaire
          customer_name: vm.donor.forName, //Le nom du client
          customer_surname: vm.donor.name, //Le prenom du client
          customer_email: vm.donor.email, //l'email du client
          customer_phone_number: vm.numberForDebit, //l'email du client
          customer_address: vm.donor.addressLine1, //addresse du client
          customer_city: vm.donor.city, // La ville du client
          customer_country: vm.donor.country, // le code ISO du pays
          customer_state: vm.donor.country, // le code ISO l'état
          customer_zip_code: "225" // code postal
        });
        await CinetPay.waitResponse(async function(data) {
          if (data.status == "REFUSED") {
            if (alert("Votre paiement a échoué")) {
              window.location.reload();
            }
          } else if (data.status == "ACCEPTED") {
            await API.createPayment({
              amount: parseFloat(vm.amount),
              createdAt: vm.signedDateTime,
              status: "completed",
              associatedUser: `api/users/${vm.createdUserId}`,
              transactionId: vm.transactionId
            })
          }
        });
        CinetPay.onError(function() {
        });
      }
    },
    resetForm() {
      this.form = {
        username: null,
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      // define TransactionId
      this.transactionId = this.makeId(13) + Date.now();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const username = this.$v.form.username.$model;
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
          .dispatch(REGISTER, {
            email: email,
            password: password,
            username: username
          })
          .then(() => this.$router.push({ name: "dashboard" }));

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    onSelect({ iso2 }) {
      this.donor.country = iso2;
    },
    validateOffering: function() {
      if (
        this.selectedOffering !== "" &&
        this.currency !== "" &&
        this.amount !== ""
      ) {
        return true;
      } else {
        this.$toast.top("Veuillez remplir les champs requis");
      }
    },

    validatePersonalInformations: function() {
      if (
        this.donor.forName !== "" &&
        this.donor.name !== "" &&
        this.donor.addressLine1 !== "" &&
        this.donor.city !== "" &&
        this.donor.country !== "" &&
        this.donor.email !== ""
      ) {
        return true;
      } else {
        this.$toast.top("Veuillez remplir les champs requis");
      }
    },
    xofCalculation: function() {
      switch (this.currency) {
        case "XOF":
          this.xofAmount = this.amount;
          break;

        case "USD":
          this.xofAmount = this.amount * 630;
          break;

        case "EUR":
          this.xofAmount = this.amount * 656.957;
          break;

        default:
          this.xofAmount = this.amount;
          break;
      }
    },
    makeId: function(length) {
      let result = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }
  },
  created() {
    this.transactionId = this.makeId(13) + Date.now();
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    // this.signedDateTime = today.toISOString(); // 2022-07-17T21:22:40Z
    // eslint-disable-next-line no-undef
    this.signedDateTime = dateFormat(today, "yyyy-mm-dd'T'HH:MM:ss'Z'");
    // reference
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    this.referenceNumber = "" + timestamp;
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
